<template>
  <span class="badge badge-light badge-block">
    {{ label }}
    <span v-if="discount.showPercentage">
      <span v-if="discount.percentage > 0">
        {{ discount.percentage }}%
      </span>
      <span v-else>
        {{ -discount.percentage }}%
      </span>
    </span>
    <span v-else>
      <span v-if="discount.amount > 0">
        {{ discount.amount | currency }}
      </span>
      <span v-else>
        {{ -discount.amount | currency }}
      </span>
    </span>
  </span>
</template>

<script>

export default {
  name: 'discount-badge',
  components: {
  },
  props: {
    discount: Object,
  },
  data() {
    return {
    }
  },
  computed: {
    label() {
      const isDiscount = (+this.discount.percentage > 0) || (+this.discount.amount > 0)
      if (this.discount.comments) {
        return this.discount.comments
      } else if (isDiscount) {
        return 'Réduction'
      } else {
        return 'Supplément'
      }
    },
  },
  methods: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.badge {
  margin-right: 2px;
  margin-bottom: 2px;
}
</style>
