<template>
  <div
    class="workshop-select"
    :rel="getId()"
    v-if="seance && seance.workshops.length > 0"
  >
    <a
      :href="!initialValue"
      class="badge"
      :class="badgeColor"
      v-b-tooltip="tooltipText"
      @click.prevent="showModal"
    >
      {{ workshopsText }}
    </a>
    <b-modal
      :id="getId()"
      @cancel="onResetWorkshop"
      @ok="onChangeWorkshop"
      title="Sélection d'un atelier"
      class="bv-workshop-select-modal"
    >
      <b-form v-if="formOk">
        <b-form-group
          v-for="moment of moments"
          :key="moment"
          :id="'workshop-group' + moment"
          :label="'Atelier ' + getMomentName(moment)"
          :label-for="'workshop' + moment"
        >
          <b-form-select
            :id="'workshop' + moment"
            v-model="selectedWorkshops[moment]"
            required
            :rel="selectedWorkshops[moment] ? selectedWorkshops[moment].id : 0"
          >
            <b-form-select-option
              v-for="elt in allWorkshops[moment]"
              v-bind:key="elt.id"
              :value="elt"
            >
              {{ getLabel(elt) }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { currency } from '@/filters/texts'
import { makeWorkshop, WorkshopMoments } from '@/types/youth'
import { getRandomId } from '@/utils/random'

export default {
  name: 'WorkshopSelect',
  props: {
    seance: Object,
    individual: Object,
    initialValue: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      randomId: getRandomId(),
      workshops: [],
      noWorkshop: null,
      allWorkshops: {},
      selectedWorkshops: {},
      workshopsText: '',
      tooltipText: '',
      isDefault: true,
      formOk: false,
    }
  },
  watch: {
    seance: function() {
      this.setWorkshops()
    },
    individual: function() {
      this.setWorkshops()
    },
    initialValue: function() {
      this.setWorkshops()
    },
    workshopInscriptions: function() {
      this.setWorkshops()
    },
  },
  computed: {
    workshopInscriptions() {
      return this.seance.getWorkshopInscriptions(this.individual.id)
    },
    originalWorkshopInscriptions() {
      return this.seance.getOriginalWorkshopInscriptions(this.individual.id)
    },
    moments() {
      return this.seance.getWorkshopMoments()
    },
    badgeColor() {
      if (this.isDefault) {
        return 'badge-light'
      } else {
        for (const workshop of this.getSelectedWorkshops()) {
          if (workshop.maxNumber) {
            const remaining = workshop.maxNumber - workshop.inscriptionsCount
            if (remaining <= 0) {
              return 'badge-error'
            } else if (remaining <= 2) {
              return 'badge-warning'
            } else {
              return 'badge-success'
            }
          }
        }
        return 'badge-dark'
      }
    },
  },
  methods: {
    getMomentName(moment) {
      switch (moment) {
        case WorkshopMoments.Morning:
          return 'Matin'
        case WorkshopMoments.Lunch:
          return 'Repas'
        case WorkshopMoments.Afternoon:
          return 'Après-Midi'
        case WorkshopMoments.Evening:
          return 'Soir'
      }
      return ''
    },
    setWorkshops() {
      this.initWorkshops()
      let items = this.initialValue ? this.originalWorkshopInscriptions : this.workshopInscriptions
      let workshopsElements = []
      let tooltipsElements = []
      let showMoments = false
      let firstMoment = WorkshopMoments.None
      for (let workshop of this.workshops) {
        if (firstMoment === WorkshopMoments.None) {
          firstMoment = workshop.moment
        } else if (workshop.moment !== firstMoment) {
          showMoments = true
        }
      }
      workshopsElements = items.filter(
        item => (item.workshop > 0)
      ).map(
        item => {
          let workshop = this.seance.getWorkshop(item.workshop)
          if (workshop) {
            let name = ''
            if (showMoments) {
              name = this.getMomentName(workshop.moment) + ': ' + workshop.name
            } else {
              name = workshop.name
            }
            return name
          }
          return 'Pas d\'atelier'
        }
      )
      tooltipsElements = items.filter(
        item => (item.workshop > 0)
      ).map(
        item => {
          let workshop = this.seance.getWorkshop(item.workshop)
          if (workshop) {
            let name = ''
            if (showMoments) {
              name = this.getMomentName(workshop.moment) + ': ' + workshop.name
            } else {
              name = workshop.name
            }
            if (workshop.maxNumber) {
              const remaining = workshop.maxNumber - workshop.inscriptionsCount
              if (remaining <= 0) {
                name += ' [COMPLET]'
              } else {
                name += ' [reste ' + remaining + ((remaining > 1) ? ' places]' : ' place]')
              }
            }
            return name
          }
          return 'Pas d\'atelier'
        }
      )
      this.isDefault = (workshopsElements.length === 0)
      if (this.isDefault) {
        this.workshopsText = this.noWorkshop.name
        this.tooltipText = ''
      } else {
        this.workshopsText = workshopsElements.join(', ')
        this.tooltipText = tooltipsElements.join(', ')
      }
    },
    getSelectedWorkshops() {
      const selectedWorkshops = []
      for (const workshop of this.seance.workshops) {
        let momentWorkshop = this.selectedWorkshops[workshop.moment]
        let momentWorkshopId = momentWorkshop ? momentWorkshop.id : 0
        if (momentWorkshopId === workshop.id) {
          selectedWorkshops.push(workshop)
        }
      }
      return selectedWorkshops
    },
    getId() {
      return 'bv-modal-workshop-select' + this.randomId
    },
    onChangeWorkshop() {
      for (let moment of this.moments) {
        let workshop = this.selectedWorkshops[moment]
        let workshopId = workshop ? workshop.id : 0
        this.seance.setWorkshopInscription(this.individual.id, moment, workshopId)
      }
    },
    onResetWorkshop() {
      this.selectedWorkshop = this.workshop
    },
    showModal() {
      if (!this.initialValue) {
        this.initForm()
        this.$bvModal.show(this.getId())
      }
    },
    initWorkshops() {
      this.selectedWorkshops = {}
      this.allWorkshops = {}
      let inscriptions = this.workshopInscriptions
      for (let workshop of this.seance.workshops) {
        // crée la liste des ateliers pour chaque moment de la journée
        // si aucun atelier pour un moment celui-ci est ignoré
        if (!(workshop.moment in this.allWorkshops)) {
          this.allWorkshops[workshop.moment] = [this.noWorkshop]
          this.selectedWorkshops[workshop.moment] = this.noWorkshop
        }
        this.allWorkshops[workshop.moment].push(workshop)
        // verifie si l'atelier est celui défini pour cette séance et ce moment
        for (let inscription of inscriptions) {
          if ((inscription.moment === workshop.moment) && (inscription.workshop === workshop.id)) {
            this.selectedWorkshops[workshop.moment] = workshop
          }
        }
      }
    },
    initForm() {
      this.formOk = false
      this.initWorkshops()
      this.formOk = true
    },
    getLabel(elt) {
      let label = elt.name
      if (elt.id) {
        if (elt.price) {
          label += ' [' + currency(elt.price) + ']'
        } else {
          label += ' [Gratuit]'
        }
      }
      if (elt.maxNumber) {
        const places = '' + elt.maxNumber + ((elt.maxNumber > 1) ? ' places' : ' place')
        const remaining = elt.maxNumber - elt.inscriptionsCount
        label += ' - limité à ' + elt.maxNumber + ' places, '
        if (remaining > 0) {
          const inscriptions = '' + remaining + ((remaining > 1) ? ' places' : ' place')
          label += ' reste ' + inscriptions
        } else {
          label += ' COMPLET'
        }
      }
      return label
    },
  },
  mounted() {
    this.noWorkshop = makeWorkshop({ name: 'Pas d\'atelier', })
    this.setWorkshops()
  },
}
</script>
<style lang="less">
.workshop-select {
  display: inline-block;
  margin: 0 2px;
}
.workshop-select .badge {
  max-width: 100px;
  white-space: normal;
}
</style>
