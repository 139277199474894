<template>
  <div class="help-icon">
    <a href @click.prevent="showModal">
      <i class="fa fa-info-circle"></i>
    </a>
    <b-modal :id="getId()" hide-footer>
      <template v-slot:modal-title>
        <b><i class="fa fa-info-circle"></i> Aide</b>
      </template>
      <div>
        {{ helpText }}
      </div>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { getRandomId } from '@/utils/random'

export default {
  name: 'HelpIcon',
  props: {
    helpText: String,
  },
  data() {
    return {
      randomId: getRandomId(),
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-help-text' + this.randomId
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .help-icon {
    display: inline-block;
  }
  .help-icon a {
    color: #656565 !important;
  }
</style>
