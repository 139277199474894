<template>
  <span>
    <span
      class="seance-limits-indicator"
      v-if="hasGroupLimits"
      v-b-tooltip.hover
      :title="groupTitle"
      :class="groupClass"
    >
      {{ groupMinText }}
    </span>
    <span
      class="seance-limits-indicator"
      v-if="hasDayLimits"
      v-b-tooltip.hover
      :title="dayTitle"
      :class="dayClass"
    >
      {{ dayMinText }}
    </span>
    <span
      class="seance-limits-indicator"
      v-if="hasSeanceGroupLimits"
      v-b-tooltip.hover
      :title="seanceGroupTitle"
      :class="seanceGroupClass"
    >
      {{ seanceGroupMinText }}
    </span>
    <span
      class="seance-limits-indicator"
      v-if="hasSeanceLimits"
      v-b-tooltip.hover
      :title="seanceTitle"
      :class="seanceClass"
    >
      {{ seanceMinText }}
    </span>
    <span
      class="seance-limits-indicator"
      v-if="hasExcursionGroupLimits"
      v-b-tooltip.hover
      :title="excursionGroupTitle"
      :class="excursionGroupClass"
    >
      {{ excursionGroupMinText }}
    </span>
    <span
      class="seance-limits-indicator"
      v-if="hasExcursionLimits"
      v-b-tooltip.hover
      :title="excursionTitle"
      :class="excursionClass"
    >
      {{ excursionMinText }}
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'SeanceLimitsDisplay',
  components: {
  },
  props: {
    groupLimits: Array,
    dayLimits: Array,
    seanceGroupLimits: Array,
    seanceLimits: Array,
    excursionLimits: Array,
    excursionGroupLimits: Array,
    ageGroup: Object,
  },
  data() {
    return {
      limit: false,
    }
  },
  watch: {
  },
  computed: {
    groupTitle() {
      const title = this.getLimitsTitle(this.groupLimits)
      if (title) {
        return this.ageGroup.name + ': ' + title
      }
      return ''
    },
    dayTitle() {
      const title = this.getLimitsTitle(this.dayLimits)
      if (title) {
        return 'Journée ' + title
      }
      return ''
    },
    seanceGroupTitle() {
      const title = this.getLimitsTitle(this.seanceGroupLimits)
      if (title) {
        return 'Séance ' + this.ageGroup.name + ': ' + title
      }
      return ''
    },
    seanceTitle() {
      const title = this.getLimitsTitle(this.seanceLimits)
      if (title) {
        return 'Séance: ' + title
      }
      return ''
    },
    excursionGroupTitle() {
      const title = this.getLimitsTitle(this.excursionGroupLimits)
      if (title) {
        return 'Sortie ' + this.ageGroup.name + ': ' + title
      }
      return ''
    },
    excursionTitle() {
      const title = this.getLimitsTitle(this.excursionLimits)
      if (title) {
        return 'Sortie: ' + title
      }
      return ''
    },
    hasGroupLimits() {
      return this.groupLimits.length > 0
    },
    hasDayLimits() {
      return this.dayLimits.length > 0
    },
    hasSeanceGroupLimits() {
      return this.seanceGroupLimits.length > 0
    },
    hasSeanceLimits() {
      return this.seanceLimits.length > 0
    },
    hasExcursionLimits() {
      return this.excursionLimits.length > 0
    },
    hasExcursionGroupLimits() {
      return this.excursionGroupLimits.length > 0
    },
    groupMinText() {
      return this.ageGroup.name + ': ' + this.getCounter(this.groupLimits)
    },
    dayMinText() {
      return 'J: ' + this.getCounter(this.dayLimits)
    },
    seanceGroupMinText() {
      return this.ageGroup.name + ': ' + this.getCounter(this.seanceGroupLimits)
    },
    seanceMinText() {
      return 'S:' + this.getCounter(this.seanceLimits)
    },
    excursionGroupMinText() {
      return this.ageGroup.name + ': ' + this.getCounter(this.excursionGroupLimits)
    },
    excursionMinText() {
      return 'Ext: ' + this.getCounter(this.excursionLimits)
    },
    groupClass() {
      return this.getCssClass(this.groupLimits)
    },
    dayClass() {
      return this.getCssClass(this.dayLimits)
    },
    seanceGroupClass() {
      return this.getCssClass(this.seanceGroupLimits)
    },
    seanceClass() {
      return this.getCssClass(this.seanceLimits)
    },
    excursionGroupClass() {
      return this.getCssClass(this.excursionGroupLimits)
    },
    excursionClass() {
      return this.getCssClass(this.excursionLimits)
    },
  },
  methods: {
    getLimitsTitle(limits) {
      let title = ''
      for (const limit of limits) {
        if (limit.limit > 0) {
          if (title) {
            title += ' - '
          }
          if (limit.label) {
            title += limit.label + ' '
          }
          title += '' + limit.inscriptions + '/' + limit.limit
        }
      }
      return title
    },
    getMinimum(limits) {
      let minLimit = 0
      let minInscriptions = 0
      for (const limit of limits) {

      }
      return minLimit
    },
    getCounter(limits) {
      let minLimit = 0
      let minInscriptions = 0
      const warnings = []
      for (const limit of limits) {
        let prefix = limit.shortLabel()
        if (prefix) {
          prefix += ':'
        }
        if (limit.inscriptions >= (limit.limit - 5)) {
          warnings.push(prefix + limit.inscriptions + '/' + limit.limit)
        }
        if ((minLimit === 0) || (minLimit > limit.limit)) {
          minLimit = limit.limit
        }
      }
      if (warnings.length) {
        return warnings.join(' ').trim()
      } else {
        return '' + minLimit
      }
    },
    getCssClass(limits) {
      let warning = false
      let full = false
      let overflow = false
      for (const limit of limits) {
        if (limit.inscriptions >= (limit.limit - 5)) {
          warning = true
        }
        if (limit.inscriptions === limit.limit) {
          full = true
        }
        if (limit.inscriptions > limit.limit) {
          overflow = true
        }
      }
      if (overflow) {
        return 'overflow'
      } else if (full) {
        return 'full'
      } else if (warning) {
        return 'warning'
      }
      return ''
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.seance-limits-indicator {
  border-radius: 8px;
  padding: 2px 4px;
  display: inline-block;
  background: #e1ffdd;
  color: #222;
  font-size: 11px;
  text-align: center;
  margin: 2px 2px;
  border: solid 1px #222;
}
.seance-limits-indicator.warning {
  background: #ebe770;
}
.seance-limits-indicator.full {
  background: #ff6e6e;
}
.seance-limits-indicator.overflow {
  background: #e96eff;
}
</style>
