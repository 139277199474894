<template>
  <span>
    <loading-gif short :loading-name="limitIndicatorLoadingName"></loading-gif>
    <seance-limits-display
      :group-limits="groupLimits"
      :day-limits="dayLimits"
      :seance-group-limits="seanceGroupLimits"
      :seance-limits="seanceLimits"
      :excursion-group-limits="excursionGroupLimits"
      :excursion-limits="excursionLimits"
      :age-group="ageGroup"
    >
    </seance-limits-display>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import SeanceLimitsDisplay from '@/components/SeanceLimits/SeanceLimitsDisplay.vue'
import { BackendApi } from '@/utils/http'
import { getLimits, makeAgeGroup } from '@/types/youth'

export default {
  name: 'SeanceLimitsLoader',
  components: {
    SeanceLimitsDisplay,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    seance: Object,
    individual: Object,
  },
  data() {
    return {
      groupLimits: [],
      dayLimits: [],
      seanceGroupLimits: [],
      seanceLimits: [],
      excursionGroupLimits: [],
      excursionLimits: [],
      ageGroup: null,
    }
  },
  watch: {
    seance: function(seance) {},
    individual: function(individual) {},
  },
  computed: {
    limitIndicatorLoadingName() {
      if (this.individual && this.seance) {
        return 'limit-indicator:' + this.individual.id + ':' + this.seance.id
      }
      return 'limit-indicator'
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadLimits() {
      if (this.seance && this.individual && !this.seance.parent) {
        this.startLoading(this.limitIndicatorLoadingName)
        const url = '/api/youth/seance-limits-check/' + this.seance.id + '/' + this.individual.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.groupLimits = getLimits(resp.data['group_limits'], resp.data['group_inscriptions'])
          this.dayLimits = getLimits(resp.data['day_limits'], resp.data['day_inscriptions'])
          this.seanceGroupLimits = getLimits(resp.data['seance_group_limits'], resp.data['seance_group_inscriptions'])
          this.seanceLimits = getLimits(resp.data['seance_limits'], resp.data['seance_inscriptions'])
          this.excursionGroupLimits = getLimits(
            resp.data['excursion_group_limits'],
            resp.data['excursion_group_inscriptions']
          )
          this.excursionLimits = getLimits(
            resp.data['excursion_limits'],
            resp.data['excursion_inscriptions']
          )
          this.ageGroup = makeAgeGroup(resp.data['age_group'])
          const event = {
            individual: this.individual,
            seance: this.seance,
            groupLimits: this.groupLimits,
            dayLimits: this.dayLimits,
            seanceGroupLimits: this.seanceGroupLimits,
            seanceLimits: this.seanceLimits,
            excursionGroupLimits: this.excursionGroupLimits,
            excursionLimits: this.excursionLimits,
            ageGroup: this.ageGroup,
          }
          this.$emit('limits-loaded', event)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.limitIndicatorLoadingName)
      }
    },
  },
  mounted() {
    this.loadLimits()
  },
}
</script>
<style lang="less">
</style>
