
import { Individual, Entity } from '@/types/people'
import {
  EntitySeance, InscriptionRule, SeanceType, YouthHome, makeInscriptionRule, SeanceInscription,
  makeEntitySeance, newSeanceInscription, SeancePeriod, WorkshopInscription
} from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { makeSeancePrice, SeancePrice } from '@/types/tariffs'
import { Discount } from '@/types/payments'
import moment from 'moment/moment'

export class SeanceInscriptionManager {
  private groupColors: Map<number, string> = new Map<number, string>()
  private inscriptionPossibilitiesMap: Map<string, boolean> = new Map<string, boolean>()
  private allPrices: any = {}
  private refundCancellations: any = {}
  private allDiscounts: any = {}
  private userDiscounts: any = {}
  private rules: InscriptionRule[] = []
  private individualSeances: EntitySeance[] = []
  private selectedIndividual: Individual|null = null
  private selectedEntity: Entity|null = null

  public constructor(
    private youthHome: YouthHome,
    private seanceType: SeanceType,
    private seancePeriod: SeancePeriod
  ) {
  }

  public setIndividual(individual: Individual, enyity: Entity) {
    this.selectedIndividual = individual
    this.selectedEntity = enyity
  }

  public newInscriptions(): SeanceInscription[] {
    let newInscriptions = []
    if (this.selectedIndividual) {
      for (let seance of this.individualSeances) {
        if (seance.hasNewInscriptions()) {
          if (seance.isIndividualInscriptionNew(this.selectedIndividual.id)) {
            newInscriptions.push(newSeanceInscription(0, seance, this.selectedIndividual))
          }
        }
      }
    }
    return newInscriptions
  }

  public cancellations(): SeanceInscription[] {
    let cancellations = []
    if (this.selectedIndividual) {
      for (let seance of this.individualSeances) {
        if (seance.hasCancellations()) {
          if (seance.isIndividualInscriptionCancelled(this.selectedIndividual.id)) {
            cancellations.push(newSeanceInscription(0, seance, this.selectedIndividual))
          }
        }
      }
    }
    return cancellations
  }

  private initializeRulesColors() {
    let primaryColors = ['#acdff8', '#ffa2a2', '#fbff8d', '#888']
    let secondaryColors = ['#ffe189', '#94ff94', '#ff9bff', '#ccc']
    let primaryCounter = 0
    let secondaryCounter = 0
    for (let rule of this.rules) {
      if (rule.isMain) {
        this.groupColors.set(rule.id, primaryColors[primaryCounter])
        primaryCounter += 1
        if (primaryCounter >= primaryColors.length) {
          primaryCounter = 0
        }
      } else {
        this.groupColors.set(rule.id, secondaryColors[secondaryCounter])
        secondaryCounter += 1
        if (secondaryCounter >= secondaryColors.length) {
          secondaryCounter = 0
        }
      }
    }
  }

  public getRuleFromCode(code: string): InscriptionRule|null {
    for (let rule of this.rules) {
      if (rule.match(code)) {
        return rule
      }
    }
    return null
  }

  public getRule(seance: EntitySeance): InscriptionRule|null {
    return this.getRuleFromCode(seance.getCodeName())
  }

  public getPrimarySeances(seance: EntitySeance): EntitySeance[] {
    let primarySeances = []
    let seanceRule = this.getRule(seance)
    if (seanceRule && !seanceRule.isMain) {
      for (const elt of this.individualSeances) {
        let rule = this.getRule(elt)
        if (rule && rule.isMain) {
          primarySeances.push(elt)
        }
      }
    }
    return primarySeances
  }

  public getRulesSeances(seance: EntitySeance, rule: InscriptionRule): EntitySeance[] {
    if (rule.daily) {
      return this.individualSeances.filter(
        elt => (seance.date === elt.date) && (seance.id !== elt.id)
      )
    } else {
      return this.individualSeances
    }
  }

  public getSecondarySeances(seance: EntitySeance) {
    let secondarySeances = []
    let seanceRule = this.getRule(seance)
    if (seanceRule && seanceRule.isMain) {
      for (const elt of this.getRulesSeances(seance, seanceRule)) {
        let rule = this.getRule(elt)
        if (rule && !rule.isMain) {
          secondarySeances.push(elt)
        }
      }
    }
    return secondarySeances
  }

  private initializeRules() {
    for (let seance of this.individualSeances) {
      let primarySeances = this.getPrimarySeances(seance)
      if (primarySeances.length && this.selectedIndividual) {
        let hasPrimarySelected = false
        for (let i = 0; (i < primarySeances.length) && !hasPrimarySelected; i++) {
          let primarySeance = primarySeances[i]
          if (primarySeance.doesIndividualHaveInscription(this.selectedIndividual.id)) {
            hasPrimarySelected = true
          }
        }
        if (!hasPrimarySelected) {
          this.markInscriptionPossible(seance, this.selectedIndividual, false)
        }
      }
    }
  }

  public markInscriptionPossible(seance: EntitySeance, individual: Individual, value: boolean) {
    let key = '' + seance.id + '-' + individual.id
    this.inscriptionPossibilitiesMap.set(key, value)
  }

  public isInscriptionPossible(seance: EntitySeance, individual: Individual): boolean {
    if (seance.parent) {
      return false
    }
    let key = '' + seance.id + '-' + individual.id
    if (this.inscriptionPossibilitiesMap.has(key)) {
      return !!this.inscriptionPossibilitiesMap.get(key)
    }
    return true
  }

  public getLinkedSeances(seance: EntitySeance) {
    let linkedSeances = []
    for (let elt of this.individualSeances.filter(elt => elt.id !== seance.id)) {
      for (let rule of this.rules) {
        if (
          (rule.seanceCodes.indexOf(seance.getCodeName()) >= 0) &&
          (rule.seanceCodes.indexOf(elt.getCodeName()) >= 0)
        ) {
          linkedSeances.push(elt)
        }
      }
    }
    return linkedSeances
  }

  public getChildrenSeances(seance: EntitySeance) {
    return this.individualSeances.filter(elt => seance.children.indexOf(elt.id) >= 0)
  }

  private notifyInscriptionChanged(seance: EntitySeance, individual: Individual, inscription: boolean) {
  }

  public setIndividualInscription(seance: EntitySeance, individual: Individual) {
    let inscription = seance.toggleIndividualInscription(individual.id)
    this.notifyInscriptionChanged(seance, individual, inscription)
    if (inscription) {
      // si inscription : on décoche des séances qui ne peuvent pas correspondre
      let linkedSeances = this.getLinkedSeances(seance)
      for (let linkedSeance of linkedSeances) {
        // désinscrire pour cette séance
        linkedSeance.resetIndividualInscription(individual.id)
        this.notifyInscriptionChanged(linkedSeance, individual, false)
      }
    }
    // modifie les séances qui en dépendent (exemple journées suivantes d'un camp)
    let childrenSeances = this.getChildrenSeances(seance)
    for (let childSeance of childrenSeances) {
      if (inscription) {
        childSeance.setIndividualInscription(individual.id)
      } else {
        childSeance.resetIndividualInscription(individual.id)
      }
      this.notifyInscriptionChanged(childSeance, individual, inscription)
    }
    let secondarySeances = this.getSecondarySeances(seance)
    for (let secondarySeance of secondarySeances) {
      if (inscription) {
        this.markInscriptionPossible(secondarySeance, individual, true)
      } else {
        // désinscrire pour cette séance
        secondarySeance.resetIndividualInscription(individual.id)
        this.notifyInscriptionChanged(secondarySeance, individual, false)
        this.markInscriptionPossible(secondarySeance, individual, false)
      }
    }
    return inscription
  }

  public getSeanceColor(seance: EntitySeance): string {
    let rule = this.getRule(seance)
    if (rule) {
      if (this.groupColors.has(rule.id)) {
        return this.groupColors.get(rule.id) || ''
      }
    }
    return 'transparent'
  }

  public async loadInscriptionRules() {
    let url = '/api/youth/inscription-rules/' + this.seanceType.id + '/' + this.youthHome.id + '/'
    let backendApi = new BackendApi('get', url)
    try {
      let resp = await backendApi.callApi()
      this.rules = resp.data.map((elt: any) => makeInscriptionRule(elt))
      this.initializeRulesColors()
    } catch (err) {
      throw err
    }
  }

  public getInscriptionRules(): InscriptionRule[] {
    return this.rules
  }

  public setInscriptionRules(rules: InscriptionRule[]) {
    this.rules = rules
    this.initializeRulesColors()
    this.finalizeSeances(this.individualSeances)
  }

  public getPriceKey(seanceId: number, individualId: number): string {
    return '' + seanceId + '/' + individualId
  }

  public getPriceObj(inscription: SeanceInscription): SeancePrice|null {
    let priceKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
    if (priceKey in this.allPrices) {
      return this.allPrices[priceKey]
    }
    return null
  }

  public getBasePrice(inscription: SeanceInscription) {
    let obj = this.getPriceObj(inscription)
    let price = obj ? obj.getPrice() : 0
    return price
  }

  public getWorkshopPrice(inscription: SeanceInscription, workshopInscription: WorkshopInscription) {
    let price = 0
    const workshop = inscription.seance.getWorkshop(workshopInscription.workshop)
    if (workshop && workshop.price) {
      const basePrice = workshop.price
      price = basePrice
      if (workshop.discountable) {
        for (const discount of this.getDiscounts(inscription)) {
          if (discount.percentage) {
            price -= basePrice * discount.percentage / 100
          }
        }
      }
    }
    return price
  }

  public getFinalPrice(inscription: SeanceInscription) {
    let price = this.getBasePrice(inscription)
    let basePrice = price
    for (const discount of this.getDiscounts(inscription)) {
      if (!discount.showPercentage && discount.amount) {
        price -= discount.amount
      }
      if (discount.showPercentage && discount.percentage) {
        price -= basePrice * discount.percentage / 100
      }
    }
    let workshopInscriptions = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
    for (const wsIns of workshopInscriptions) {
      price += this.getWorkshopPrice(inscription, wsIns)
    }
    return price
  }

  public isInvoiced(inscription: SeanceInscription) {
    let obj = this.getPriceObj(inscription)
    return (obj !== null) ? obj.isInvoiced() : false
  }

  public getWelfare(inscription: SeanceInscription) {
    let priceKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
    if (priceKey in this.allPrices) {
      return this.allPrices[priceKey].welfare
    }
    return []
  }

  public isCancellationRefund(cancellation: SeanceInscription): boolean {
    let key = cancellation.getKey()
    if (this.refundCancellations.hasOwnProperty(key)) {
      return this.refundCancellations[key]
    }
    return false
  }

  public changeCancellationRefund(cancellation: SeanceInscription, value: boolean) {
    this.refundCancellations[cancellation.getKey()] = value
    this.refundCancellations = { ...this.refundCancellations, }
  }

  public isCancellationCharged(cancellation: SeanceInscription): boolean {
    return !this.isCancellationRefund(cancellation)
  }

  public changeCancellationCharged(cancellation: SeanceInscription, value: boolean) {
    this.changeCancellationRefund(cancellation, !value)
  }

  public getDiscounts(inscription: SeanceInscription): Discount[] {
    const discounts = []
    const discountKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
    const price = this.getBasePrice(inscription)
    if (price) {
      if (discountKey in this.allDiscounts) {
        let discount = this.allDiscounts[discountKey]
        if (discount) {
          discounts.push(discount)
        }
      }
      const individualId: number = inscription.individual.id
      if (this.userDiscounts[individualId]) {
        discounts.push(this.userDiscounts[individualId])
      }
    }
    return discounts
  }

  public setUserDiscounts(discounts: any) {
    if (discounts) {
      this.userDiscounts = discounts
    } else {
      this.userDiscounts = {}
    }
  }

  public async loadPrices() {
    const newInscriptions = this.newInscriptions()
    const cancellations = this.cancellations()
    await this.calculateInscriptionsPrices(newInscriptions, cancellations)
    for (const cancellation of cancellations) {
      let key = cancellation.getKey()
      if (!this.refundCancellations.hasOwnProperty(key)) {
        this.refundCancellations[key] = !this.isInvoiced(cancellation)
      }
    }
    this.refundCancellations = { ...this.refundCancellations, }
  }

  public async calculateInscriptionsPrices(newInscriptions: SeanceInscription[], cancellations: SeanceInscription[]) {
    if (this.selectedEntity && this.selectedEntity.id > 0) {
      let data = []
      for (const inscription of newInscriptions) {
        data.push(
          {
            seance: inscription.seance.id,
            individual: inscription.individual.id,
            cancelled: false,
          }
        )
      }
      for (let inscription of cancellations) {
        data.push(
          {
            seance: inscription.seance.id,
            individual: inscription.individual.id,
            cancelled: true,
          }
        )
      }
      let url = '/api/youth/entity-seances-prices/' + this.selectedEntity.id + '/'
      let backendApi = new BackendApi('post', url)
      let prices: any = {}
      try {
        let resp = await backendApi.callApi(data)
        for (let elt of resp.data) {
          let priceKey = this.getPriceKey(elt.seance, elt.individual)
          prices[priceKey] = makeSeancePrice(elt)
        }
        this.allPrices = { ...prices, }
      } catch (err) {
        throw err
      }
    }
  }

  /**
   * Appel Backend avec confirmation des inscriptions
   * @param callback : fonction appelé en fin pour MAJ des inscriptions si nécessaire
   * @param createInvoice : crée la facture après confirmation
   */
  public async confirmInscriptions(callback: any|null = null, createInvoice: boolean = false) {
    if (this.selectedIndividual && this.selectedEntity) {
      let data = []
      for (const inscription of this.cancellations()) {
        let workshops = inscription.seance.getOriginalWorkshopInscriptions(inscription.individual.id)
        data.push(
          {
            seance: inscription.seance.id,
            individual: inscription.individual.id,
            cancelled: true,
            refund: this.isCancellationRefund(inscription),
            discounts: [],
            workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
          }
        )
      }
      for (const inscription of this.newInscriptions()) {
        let workshops = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
        const discounts = this.getDiscounts(inscription).map(
          (discount: Discount) => {
            if (discount.showPercentage) {
              return {
                amount: 0, comments: discount.comments, percentage: discount.percentage,
              }
            } else {
              return {
                amount: discount.amount, comments: discount.comments, percentage: 0,
              }
            }
          }
        )
        data.push(
          {
            seance: inscription.seance.id,
            individual: inscription.individual.id,
            cancelled: false,
            workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
            discounts: discounts,
          }
        )
      }
      let url = '/api/youth/entity-create-inscriptions/' + this.selectedEntity.id + '/'
      if (createInvoice) {
        url += '?create-invoice=1'
      }
      let backendApi = new BackendApi('post', url)
      try {
        let resp = await backendApi.callApi(data)
        let updatedSeances = resp.data.seances.map((elt: any) => makeEntitySeance(elt))
        let existingSeanceIds = this.individualSeances.map(elt => elt.id)
        let createdInscriptions: SeanceInscription[] = []
        let cancelledSeances: number[] = []
        for (let updatedSeance of updatedSeances) {
          let seanceIndex = existingSeanceIds.indexOf(updatedSeance.id)
          if (seanceIndex >= 0) {
            let seance = this.individualSeances[seanceIndex]
            seance.reinit(updatedSeance)
            const inscriptionId = updatedSeance.inscriptions[this.selectedIndividual.id] || 0
            const isNew = updatedSeance.doesIndividualHaveInscription(this.selectedIndividual.id)
            if (isNew) {
              const inscription = newSeanceInscription(
                inscriptionId, seance, this.selectedIndividual
              )
              createdInscriptions.push(inscription)
            } else {
              cancelledSeances.push(updatedSeance.id)
            }
          }
        }
        if (callback) {
          callback(createdInscriptions, cancelledSeances)
        }
      } catch (err) {
        throw err
      }
    }
    return []
  }

  public getSeances(): EntitySeance[] {
    return this.individualSeances
  }

  public setSeances(seances: EntitySeance[]) {
    this.individualSeances = seances
  }

  private finalizeSeances(entitySeances: EntitySeance[]) {
    this.individualSeances = entitySeances.sort(
      (seance1: EntitySeance, seance2: EntitySeance) => {
        if (seance1.order === seance2.order) {
          const rule1 = this.getRule(seance1)
          const rule2 = this.getRule(seance2)
          // En 1er les codes qui ont une règle associée : primaire puis secondaire
          // classés ensuite par moment de la journée Matin, Repas, Après-Midi, Soir
          // puis durée puis par ordre alpha du code
          if ((rule1 === null) && rule2) {
            return 1
          } else if ((rule2 === null) && rule1) {
            return -1
          } else if ((rule2 === null) && (rule1 === null)) {
            return -1
          } else if ((rule2 === null) || (rule1 === null) || (rule2 === rule1) || (rule2.id === rule1.id)) {
            const sortNumber1 = seance1.sortNumber()
            const sortNumber2 = seance2.sortNumber()
            if (sortNumber1 > sortNumber2) {
              return 1
            } else if (sortNumber1 < sortNumber2) {
              return -1
            } else {
              const duration1 = seance1.duration
              const duration2 = seance2.duration
              if (duration1 === duration2) {
                const code1 = seance1.getCodeName()
                const code2 = seance2.getCodeName()
                return (code1 === code2) ? 0 : ((code1 > code2) ? 1 : -1)
              } else {
                return duration1 > duration2 ? 1 : -1
              }
            }
          }
          if (rule1.isMain && !rule2.isMain) {
            return -1
          }
          if (!rule1.isMain && rule2.isMain) {
            return 1
          }
          return (rule1.id > rule2.id) ? 1 : -1
        } else {
          return (seance1.order > seance2.order) ? 1 : -1
        }
      }
    )
    this.initializeRules()
  }

  public async loadIndividualSeances(seanceDate: Date) {
    this.individualSeances = []
    if (this.selectedEntity && this.selectedIndividual) {
      const dayAsString = moment(seanceDate).format('YYYY-MM-DD')
      let url = '/api/youth/daily-individual-seances/' + this.selectedEntity.id + '/' + this.selectedIndividual.id
      url += '/' + dayAsString
      url += '/' + this.youthHome.id + '/' + this.seanceType.id + '/' + this.seancePeriod.id + '/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        let entitySeances = resp.data.map((elt: any) => makeEntitySeance(elt))
        this.finalizeSeances(entitySeances)
      } catch (err) {
        throw err
      }
    }
  }
}

export function filterDay(seancesFilter: any, day: Date): boolean {
  if (!seancesFilter) {
    return true
  }
  let isVisible = true
  if (seancesFilter.dateFrom) {
    if (day < seancesFilter.dateFrom) {
      isVisible = false
    }
  }
  if (isVisible && seancesFilter.dateTo) {
    if (day > seancesFilter.dateTo) {
      isVisible = false
    }
  }
  if (isVisible && seancesFilter.weekDays) {
    let seanceWeekDay = moment(day).weekday()
    if (seanceWeekDay < seancesFilter.weekDays.length) {
      isVisible = seancesFilter.weekDays[seanceWeekDay]
    }
  }
  if (isVisible && seancesFilter.noOddWeeks) {
    isVisible = (moment(day).isoWeek() % 2 !== 0)
  }
  if (isVisible && seancesFilter.noEvenWeeks) {
    isVisible = (moment(day).isoWeek() % 2 !== 1)
  }
  return isVisible
}

export function filterSeance(seancesFilter: any, seance: EntitySeance): boolean {
  if (!seancesFilter) {
    return true
  }
  let isVisible: boolean = true
  if (seancesFilter.dayTimes) {
    if (seance.morning) {
      isVisible = seancesFilter.dayTimes[0]
    }
    if (seance.lunch) {
      isVisible = isVisible && seancesFilter.dayTimes[1]
    }
    if (seance.afternoon) {
      isVisible = isVisible && seancesFilter.dayTimes[2]
    }
    if (seance.evening) {
      isVisible = isVisible && seancesFilter.dayTimes[3]
    }
  }
  return isVisible
}
