<template>
  <span>
    <b-input
      type="time" v-model="time" :id="id" :required="required" :disabled="disabled"
      :class="{ 'form-control-error': error, 'small-input': small, }"
    ></b-input>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'TimePickerInput',
  prop: ['value'],
  props: {
    id: String,
    value: String,
    required: {
      type: Boolean,
      defaultValue: false,
    },
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    error: {
      type: Boolean,
      defaultValue: false,
    },
    small: {
      type: Boolean,
      defaultValue: false,
    },
  },
  computed: {
  },
  watch: {
    value: function() {
      this.init()
    },
    required: function() {},
    time: function() {
      this.$emit('input', this.time)
      this.$emit('change', this.time)
    },
  },
  data() {
    return {
      time: '',
    }
  },
  methods: {
    init() {
      this.time = this.value
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style scoped lang="less">
</style>
